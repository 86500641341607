/* -------------------------------------------------------------------------- */
/*                             Media queries mixins                           */
/* -------------------------------------------------------------------------- */

// Breakpoints
$breakpoints: (
  xsMobile: '(min-width: 200px) and (max-height: 650px)',
  landscape-mobile: '(orientation: landscape) and (max-height: 688px)',
  medium-mobile: '(min-width: 376px)',
  tablet: '(min-width: 689px)',
  desktop: '(min-width: 993px)',
  small-desktop: '(min-width: 993px)',
  medium-desktop: '(min-width: 1450px)',
  large-desktop: '(min-width: 1700px)',
);

@mixin device($selected-device) {
  @each $device-name, $size in $breakpoints {
    @if $selected-device==$device-name {
      @media #{$size} {
        @content;
      }
    }
  }
}
